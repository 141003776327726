//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyPagination from '../../my-pagination.vue'
export default {
    props: [],
    data () {
        return {
            model: '',
            syncTruename: '',

            valueArr: [],
            valueArrStr: ''
        }
    },
    methods: {
        
    },
    mounted: function() {
        
    }
}
